<template>
  <div class="row">
    <div class="col-12 col-lg-4 g">
        <div class="card">
            <div class="card-header">
                مجموعات الاعدادات <button class="btn btn-sm btn-primary" style="border-radius: 50%; height: 30px; width: 30px; padding: 0px" @click="create()"><i class="fa fa-plus"></i></button>
            </div>
            <div class="card-body">
                <div class="list-group">
                    <button type="button" :class="'list-group-item list-group-item-action ' + (current._id == group._id ? 'active' : '')" v-for="group in groups" :key="group._id" @click="current = group">
                        {{ group.title }}
                    </button>
                </div>
            </div>
        </div>
        <div class="card card-body">
            <button class="btn btn-outline-primary btn-block" v-b-modal.ex>
                <i class="fa fa-sign-out"></i>
                تفعيل الاستئذان من اجهزة البصمة
            </button>
        </div>
    </div>
    <b-modal id="ex" title="تفعيل الاستئذان من اجهزة البصمة" hide-footer size="lg">
        <div class="alert alert-info text-center g">
            <span class="text-primary">
                سيتم تعيين الاجهزة المحددة للإستئذان للطلاب في الوقت المحدد.
            </span>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        اختيار الجهاز
                    </th>
                    <th>
                        العنوان
                    </th>
                    <th>
                        وقت بداية الاستئذان
                    </th>
                    <th>
                        وقت نهاية الاستئذان
                    </th>
                </thead>
                <tbody>
                    <template v-for="device in devices">
                        <tr :key="device._id" v-if="device.type == 'students'" :class="device.ex_enable == 'yes' || device.ex_enable == true  ? 'bg-light-success' : ''">
                            <td>
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" v-model="device.ex_enable" value="yes" @change="savedevices()">
                                    تفعيل هذا الجهاز للاستئذان
                                  </label>
                                </div>
                            </td>
                            <td>
                                {{ device.title }}
                            </td>
                            <td>
                                <input type="time" :disabled="device.ex_enable == 'yes' || device.ex_enable == true  ? false : true" @change="savedevices()"
                                    class="form-control" v-model="device.ex_from">
                            </td>
                            <td>
                                <input type="time" :disabled="device.ex_enable == 'yes' || device.ex_enable == true ? false : true" @change="savedevices()"
                                    class="form-control" v-model="device.ex_to">
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </b-modal>
    <div class="col-12 col-lg-8 g" v-if="current.title">
        <div class="card">
            <div class="card-header">
                <span>
                    التحكم في المجموعة:
                    <strong>{{ current.title }}</strong>
                </span>
                <button class="btn text-danger" @click="del()">
                    <i class="fa fa-trash"></i>
                    حذف المجموعة نهائياً
                </button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-6 g">
                        <div class="form-group">
                          <label for="">عنوان المجموعة</label>
                          <input type="text"
                            class="form-control" v-model="current.title">
                        </div>
                    </div>
                    <div class="col-12"></div>
                    <div class="col-12 col-lg-6 g">
                        <div class="form-group">
                            <label for="">بداية وقت الحضور</label>
                            <input type="time"
                                class="form-control" v-model="current.start_date">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 g">
                        <div class="form-group">
                            <label for="">بداية وقت التأخر</label>
                            <input type="time"
                                class="form-control" v-model="current.late_date">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 g">
                        <div class="form-group">
                            <label for="">بداية وقت الغياب</label>
                            <input type="time"
                                class="form-control" v-model="current.ghiab_date">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 g">
                        <div class="form-group">
                            <label for="">بداية وقت الانصراف</label>
                            <input type="time"
                                class="form-control" v-model="current.end_date">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 g">
                    <strong> أيام الحضور في المدرسة: </strong>
                    </div>
                        <div
                        class="col-12 col-lg-3 g"
                        v-bind:key="day.en"
                        v-for="day in days"
                        >
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                ref="days"
                                :value="day.en"
                                v-model="xx"
                                checked
                            />
                            <input
                                type="checkbox"
                                class="form-check-input"
                                ref="days"
                                :value="day.en"
                                v-model="xx"
                            />
                            <span>
                                {{ day.ar }}
                            </span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 g"></div>
                        <div class="col-12 col-lg-4 g c">
                            <div :class="'card card-body border text-center c def ' + (current.default_ghiab ? 'active' : '')" @click="current.default_ghiab = true;">
                                <h5>
                                    <i v-if="current.default_ghiab" class="fa fa-check-square"></i>
                                    <i v-if="!current.default_ghiab" class="fa fa-square"></i>
                                    افتراضي يكون الطالب غائب
                                </h5>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 g c">
                            <div :class="'card card-body border text-center c def ' + (!current.default_ghiab ? 'active' : '')" @click="current.default_ghiab = false;">
                                <h5>
                                    <i v-if="!current.default_ghiab" class="fa fa-check-square"></i>
                                    <i v-if="current.default_ghiab" class="fa fa-square"></i>
                                    افتراضي يكون الطالب حاضر
                                </h5>
                            </div>
                        </div>
                        <div class="col-12 text-center g">
                            <button class="btn btn-primary" @click="save()">
                                <i class="fa fa-save"></i>
                                حفظ الاعدادات
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput } from "bootstrap-vue";
export default {
  components: {
    // Layouts
    BModal,BFormInput},
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            devices: [],
            groups: [],
            user: JSON.parse(localStorage.getItem('user')),
            current: {},
            xx: [],
            days: [
                {
                en: "Saturday",
                ar: "السبت",
                },
                {
                en: "Sunday",
                ar: "الأحد",
                },
                {
                en: "Monday",
                ar: "الاثنين",
                },
                {
                en: "Tuesday",
                ar: "الثلاثاء",
                },
                {
                en: "Wednesday",
                ar: "الأربعاء",
                },
                {
                en: "Thursday",
                ar: "الخميس",
                },
                {
                en: "Friday",
                ar: "الجمعة",
                },
            ],
        }
    },
    created(){
        if(!checkPer("students|settings")){
            this.$router.push('/NotPermitted')
            return false;
        }
        this.getGroups();
        this.getDevices();
    },
    methods: {
        savedevices(){
            var g = this;
            JSON.parse(JSON.stringify(g.devices)).forEach(function(a){
                if(a.ex_enable == true){
                    a.ex_enable = "yes";
                }else{
                    a.ex_enable = "no";
                }
                $.post(api + '/user/devices/save-ex', {
                    jwt: g.user.jwt,
                    id: a._id,
                    enable: a.ex_enable,
                    from: a.ex_from,
                    to: a.ex_to
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response, r.status)
                    }else{
                    }
                }).fail(function(){
                    alert("حدث خطأ", 200)
                })
            })
        },
        getDevices(){
            var g = this;
            $.post(api + '/user/devices/all', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.devices = r.response.map(function(a){
                        if(!a.ex_enable){
                            a.ex_enable = false;
                            a.ex_from = "09:00";
                            a.ex_to = "11:00";
                        }else{
                            if(a.ex_enable == "yes"){
                                a.ex_enable = true
                            }else{
                                a.ex_enable = false
                            }
                        }
                        return a;
                    })
                }
            }).fail(function(){
            })
        },
        getGroups(){
            var g = this;
             $.post(api + '/user/students/groups', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.groups = r.response
                    if(window.location.hash){
                        g.groups.forEach(element => {
                            if(element._id == window.location.hash.replace("#", "")){
                                g.current = element
                                g.xx = element.days.split(',')
                            }
                        });
                    }else{
                        g.current = g.groups[0]
                        g.xx = g.groups[0].days.split(',')
                    }
                }
            }).fail(function(){
            })
        },
        save(){
            var g = this;
             $.post(api + '/user/students/groups/save', {
                jwt: this.user.jwt,
                id: this.current._id,
                title: this.current.title,
                start_date: this.current.start_date,
                end_date: this.current.end_date,
                late_date: this.current.late_date,
                ghiab_date: this.current.ghiab_date,
                days: this.xx,
                default_ghiab: this.current.default_ghiab
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    alert(r.response, r.status)
                }
            }).fail(function(){
            })
        },
        create(){
            var g = this;
            var title = prompt("عنوان المجموعة:", "")
            if(title && title != ""){
            $.post(api + '/user/students/groups/create', {
                jwt: this.user.jwt,
                title: title
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    window.location = "#" + r.response._id
                    g.getGroups()
                }
            }).fail(function(){
            })
            }
        },
        del(){
            var g = this;
            if(confirm("متاكد من حذف المجموعة؟")){
                $.post(api + '/user/students/groups/delete', {
                    jwt: this.user.jwt,
                    id: this.current._id
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response, r.status)
                    }else{
                        alert(r.response, r.status)
                        g.current = {}
                        g.getGroups()
                    }
                }).fail(function(){
                })
            }
        },
    }
}
</script>

<style>
.def:hover{
    background: #eee !important;
    cursor: pointer;
}
.def.active{
    border: 2px solid green !important;
    background: #fafafa;
}
.def.active h5{
    color: green !important;
}
</style>